import React from "react";
import styles from './home-unique-sec.module.scss';
import { Col, Container, Row } from "react-bootstrap";
import { Satisfaction_img, UniqueSliderimg1, UniqueSliderimg2, UniqueSliderimg3, dollar_img, home_service_list_bg_img_1, transparent_img } from "../../assets/images";
import HomeUniqueSlider from './HomeUniqueSlider'

const HomeUniqueSec = () => {
    const sliderImgList = [
        {
            id: 1,
            imgSrc: UniqueSliderimg1,
            alt: "unique1"
        },
        {
            id: 2,
            imgSrc: UniqueSliderimg2,
            alt: "unique1"
        },
        {
            id: 3,
            imgSrc: UniqueSliderimg3,
            alt: "unique1"
        },
    ]
    return (
        <section className={styles.unique_design_sec}>
            <Container>
                <Row>
                    <Col lg="6" className={`${styles.unique_design_sec_lft}`}>
                        <div className="sub-heading">
                            <h2 className="text-uppercase">How Do We Craft <span>Unique Designs</span> For Your Living Space?</h2>
                        </div>
                        <ul>
                            <li>
                                <img src={transparent_img} alt="Transparency" />
                                <div>
                                    <h5>Transparency</h5>
                                    <p>Transparent consultation for your project vision.</p>
                                </div>
                            </li>
                            <li>
                                <img src={dollar_img} alt="Contractual" />
                                <div>
                                    <h5>Contractual Penalties</h5>
                                    <p>Prompt reimbursement for delayed services.</p>
                                </div>
                            </li>
                            <li>
                                <img src={Satisfaction_img} alt="Satisfaction" />
                                <div>
                                    <h5>Satisfaction</h5>
                                    <p>A Trust-based approach with no upfront payment.</p>
                                </div>
                            </li>
                        </ul>
                    </Col>
                    <Col lg="6">
                        <HomeUniqueSlider sliderImgList={sliderImgList} />
                    </Col>
                </Row>
            </Container>
            <img src={home_service_list_bg_img_1} className={styles.unique_design_bg_logo} alt="unique" />
        </section>
    )
}

export default HomeUniqueSec;