import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./contact-page-banner.module.scss";
import { phone_call_icn, envelop_icn } from "../../assets/images";
import "react-phone-number-input/style.css";
import { Link } from 'react-router-dom';
import ContactFormSubmit from "../globalComponent/ContactFormSubmit";


const ConatactPage = ({refToContact}) => {
  return (
    <>
    
      <section ref={refToContact} className={styles.contact_page_details_sec}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="4" md="6">
              <div className={styles.contact_detls_item}>
                <img src={phone_call_icn} alt="phone icon" />
                <h3>Call to Connect</h3>
                <p><Link to="tel:+13035980433">+1 303 598 0433</Link></p>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className={styles.contact_detls_item}>
                <img src={envelop_icn} alt="email icon" />
                <h3>Send Your Inquiry</h3>
                <p><Link to="mailto:contact@denverdreambuilders.com">contact@denverdreambuilders.com</Link></p>
              </div>
            </Col>
            {/* <Col lg="4" md="6">
              <div className={styles.contact_detls_item}>
                <img src={map_icn} />
                <h3>Explore Our Services</h3>
                <p>Lorem Ipsum Content lorem ipsum</p>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
      <ContactFormSubmit location="contactPage" />

    </>
  );
};
export default ConatactPage;